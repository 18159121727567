import { Redirect } from '_app/components/Redirect'
import { IconAws } from '_app/icons/IconAws'
import { IconExplorer } from '_app/icons/IconExplorer'
import { IconMetrics } from '_app/icons/IconMetrics'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import {
  ArrowRightLeft,
  Bell,
  Calculator,
  ClipboardList,
  FunctionSquare,
  Key,
  KeyIcon,
  KeyRound,
  Layers,
  LayoutGrid,
  Package,
  PlayCircle,
  Rocket,
  Settings,
  User,
  Users,
} from 'lucide-react'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

/**
 * This handles all routing for the app.
 * We utilize the 'handle' property to enrich routes with breadcrumbs and enhance each route with additional information and data.
 * We load the routes lazily to improve the initial load time of the app.
 */

/**
 * The 'Root' wraps the entire app with all required providers and private route
 */
const Root = lazy(() => import('./_app/components/Root'))
const SettingsHeader = lazy(() => import('./settings/SettingsHeader'))
const ServiceHeader = lazy(() => import('./services/components/ServiceHeader'))
const NotFoundPage = lazy(() => import('./_app/components/NotFoundPage'))
const Layout = lazy(() => import('./_app/components/Layout'))
/**
 * Root level pages
 */
const OptOutPage = lazy(() => import('./_app/components/OptOutPage'))
const GithubCallbackPage = lazy(() => import('./providers/components/GithubCallbackPage'))
const JoinOrganization = lazy(() => import('./settings/team/components/JoinOrganization'))
const Components = lazy(() => import('./Components'))

/**
 * Organization level pages
 */
const AccountSettings = lazy(() => import('./settings/general/components/AccountSettings'))
const AppsList = lazy(() => import('./applications/components/AppsList'))
const DeploymentsPage = lazy(() => import('./deployments/DeploymentsPage'))
const RegistryPage = lazy(() => import('./registry/RegistryPage'))

/**
 * Settings level pages
 */
const GeneralSettingsPage = lazy(() => import('./settings/general/GeneralSettingsPage'))
const TeamMembersPage = lazy(() => import('./settings/team/TeamPage'))
const ProvidersListPage = lazy(() => import('./providers/ProvidersListPage'))
const AccessKeysPage = lazy(() => import('./settings/accessKeys/AccessKeysPage'))
const LicenseKeysPage = lazy(() => import('./settings/licenseKeys/LicenseKeysPage'))
const BillingPage = lazy(() => import('./settings/billing/BillingPage'))
const Alerts = lazy(() => import('./settings/alerts/AlertsPage'))
const Integrations = lazy(() => import('./settings/integrations/Integrations'))

const ExplorerPage = lazy(() => import('./explorer/ExplorerPage'))
const MetricsPage = lazy(() => import('./metrics/components/Metrics'))

/**
 * Apps/Service level pages
 */
const ServiceDeployments = lazy(() => import('./deployments/components/ServiceDeployments'))
const ServiceOutputs = lazy(() => import('./services/components/ServiceOutputs'))
const ServiceMetrics = lazy(() => import('./services/components/ServiceMetrics'))
const StudioPage = lazy(() => import('./studio/components/InteractTemporaryNotice'))
const ParametersListPage = lazy(() => import('./parameters/ParametersListPage'))

export const settingsSubPages = [
  {
    path: 'general',
    element: <GeneralSettingsPage />,
    handle: {
      path: 'general',
      label: 'General',
      icon: <Settings size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'team',
    element: <TeamMembersPage />,
    handle: {
      path: 'team',
      label: 'Team',
      icon: <Users size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'providers',
    element: <ProvidersListPage />,
    handle: {
      path: 'providers',
      label: 'Providers',
      icon: <IconAws size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'alerts',
    element: <Alerts />,
    handle: {
      path: 'alerts',
      label: 'Alerts',
      icon: <Bell size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'integrations',
    element: <Integrations />,
    handle: {
      path: 'integrations',
      label: 'Integrations',
      icon: <Layers size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'accessKeys',
    element: <AccessKeysPage />,
    handle: {
      path: 'accessKeys',
      label: 'Access Keys',
      icon: <Key size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'licenseKeys',
    element: <LicenseKeysPage />,
    handle: {
      path: 'licenseKeys',
      label: 'License Keys',
      icon: <KeyRound size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'billing',
    element: <BillingPage />,
    handle: {
      path: 'billing',
      label: 'Billing',
      icon: <Calculator size={14} />,
      showBreadCrumb: true,
    },
  },
]
export const appsSubPages = [
  {
    path: 'overview',
    element: <MetricsPage />,
    handle: {
      isMonitoringView: true,
      path: 'overview',
      label: 'Overview',
      icon: <IconMetrics size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'explorer',
    element: <ExplorerPage />,
    handle: {
      isMonitoringView: true,
      path: 'explorer',
      label: 'Explorer',
      icon: <IconExplorer size={14} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'functions',
    element: <ServiceMetrics />,
    handle: {
      isMonitoringView: true,
      path: 'functions',
      label: 'Functions',
      icon: <FunctionSquare size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'endpoints',
    element: <ServiceMetrics />,
    handle: {
      isMonitoringView: true,
      path: 'endpoints',
      label: 'API',
      icon: <ArrowRightLeft size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'deploys',
    element: <ServiceDeployments />,
    handle: {
      path: 'deploys',
      label: 'Deploys',
      icon: <Rocket size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'deploys/:deploymentId',
    element: <ServiceDeployments />,
    handle: {
      hideTabBar: true,
      path: 'deploys',
      label: 'Deploys',
      icon: <Rocket size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'interact',
    element: <StudioPage />,
    handle: {
      path: 'interact',
      label: 'Interact',
      icon: <PlayCircle size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'outputs',
    element: <ServiceOutputs />,
    handle: {
      path: 'outputs',
      label: 'Outputs',
      icon: <ClipboardList size={15} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'parameters',
    element: <ParametersListPage linkType="instance" parentLinkType="service" />,
    handle: {
      path: 'parameters',
      label: 'Parameters',
      icon: <KeyIcon size={18} />,
      showBreadCrumb: true,
    },
  },
  {
    path: 'providers',
    element: <ProvidersListPage linkType="instance" parentLinkType="service" />,
    handle: {
      path: 'providers',
      label: 'Providers',
      icon: <IconAws size={15} />,
      showBreadCrumb: true,
    },
  },
]

export const routes = [
  {
    path: '/',
    element: <Root />, // This is the root component for the app. It wraps the entire app with all required providers
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'opt-out',
        element: <OptOutPage />,
        handle: {
          public: true, // Page can be show if you're unauthenticated
        },
      },
      {
        path: 'git/callback',
        element: <GithubCallbackPage />,
      },
      {
        path: 'join',
        element: <JoinOrganization />,
      },
      {
        path: ':orgName',
        element: <Layout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: 'components',
            element: <Components />,
          },
          {
            index: true, // Redirect to :orgName/apps
            element: <Redirect to="apps" replace />,
          },

          {
            path: 'me',
            element: <AccountSettings />,
            handle: {
              path: 'me',
              label: 'Account',
              icon: <User size={14} />,
              showBreadCrumb: true,
            },
          },
          {
            path: 'apps',
            errorElement: <ErrorBoundary />,
            handle: {
              showInSidebar: true,
              path: 'apps',
              label: 'Apps',
              icon: <LayoutGrid size={14} />,
              showBreadCrumb: true,
            },
            children: [
              {
                index: true, // Show apps page on /:orgName/apps
                element: <AppsList />,
                handle: {
                  path: 'apps',
                  label: 'Apps',
                  icon: <LayoutGrid size={14} />,
                },
              },
              {
                path: ':appName/:serviceName/:stage/:region',
                errorElement: <ErrorBoundary />,
                handle: {
                  showBreadCrumb: true,
                  showServiceBreadCrumbs: true,
                  header: <ServiceHeader />,
                },
                children: [
                  {
                    index: true, // Redirect to :orgName/apps
                    element: <Redirect to="apps" replace />,
                  },
                  ...appsSubPages,
                ],
              },
            ],
          },
          {
            path: 'metrics',
            label: 'Metrics',
            element: <MetricsPage />,
            handle: {
              isMonitoringView: true,
              showInSidebar: true,
              path: 'metrics',
              label: 'Metrics',
              icon: <IconMetrics size={15} />,
              showBreadCrumb: true,
            },
          },
          {
            path: 'explorer',
            label: 'Explorer',
            element: <ExplorerPage />,
            handle: {
              isMonitoringView: true,
              showInSidebar: true,
              path: 'explorer',
              label: 'Explorer',
              icon: <IconExplorer size={15} />,
              showBreadCrumb: true,
            },
          },
          {
            path: 'deploys',
            label: 'Deploys',
            element: <DeploymentsPage />,
            handle: {
              showInSidebar: true,
              path: 'deploys',
              label: 'CI/CD Deploys',
              icon: <Rocket size={15} />,
              showBreadCrumb: true,
            },
          },
          {
            path: 'registry',
            label: 'Registry',
            element: <RegistryPage />,
            handle: {
              showInSidebar: true,
              path: 'registry',
              label: 'Registry',
              icon: <Package size={15} />,
              showBreadCrumb: true,
            },
          },
          {
            path: 'settings',
            label: 'Settings',
            errorElement: <ErrorBoundary />,
            handle: {
              showInSidebar: true,
              header: <SettingsHeader />,
              path: 'settings',
              label: 'Settings',
              icon: <Settings size={14} />,
              showBreadCrumb: true,
            },
            children: [
              {
                index: true, // Redirect to /settings/general
                element: <Redirect to="general" replace />,
              },
              {
                path: '*', // Redirect to /settings/general
                element: <Redirect to="general" replace />,
              },
              ...settingsSubPages,
            ],
          },
          {
            path: '*',
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: '/*',
        element: <NotFoundPage />,
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
