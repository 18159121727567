/**
 * Config
 */
const env =
  window.location.hostname.match(/(^|\.)serverless\.com$/) || process.env.REACT_APP_ENV === 'prod'
    ? 'prod'
    : 'dev'
/**
 * Dev
 */
const devConfig = {
  host: 'https://www.serverless-dev.com',
  localStorageKey: 'serverless-dev',
  auth0: {
    domain: 'auth.serverless-dev.com',
    clientId: 'EMAtx5b2Bf3PB94c3pm9nrADxpFvyZcm',
    audience: 'https://serverlessdev.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  platform: {
    stage: 'dev',
    apiUrl: 'https://api.serverless-dev.com',
    integrationsBase: 'https://integrations.core.serverless-dev.com/api',
    coreApiUrl: 'https://core.serverless-dev.com/api',
    sp: 'https://sp-providers-v1.serverless-platform-dev.com',
    queryUrl: 'https://core.serverless-dev.com/api/v2/query',
    integrationsUrl: 'https://integrations.core.serverless-dev.com/api/integrations',
    alerts: 'https://alerts-api.core.serverless-dev.com/v1',
  },
  stripe: {
    apiKey: 'pk_test_q68RE9RoItF3A3c9Qx903A0Y004VeuwvWj',
  },
  mui: {
    licenseKey:
      'e7163c4816a16be174ebb724c5c5590aTz04OTQ3MixFPTE3NDYxMTI4MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },
  // Hotjar tracking site id and Hotjar Snippet Version
  hotjar: {
    hjid: '3478034',
    hjsv: '6',
  },

  awsMarketplacePublicSubscriptionLink:
    'https://aws.amazon.com/marketplace/pp/prodview-5b3jlue6zk5do',
  awsMarketplacePrivateSubscriptionLink:
    'https://aws.amazon.com/marketplace/saas/ordering?productId=prod-fkgmx7zmbkhts',
  calendly: 'https://slss.io/meet ', // link for users to set up a meeting
  contact: {
    support: 'support@serverless.com',
  },
  hubspot: 2901603,
}

/**
 * Prod
 */
const prodConfig = {
  host: 'https://www.serverless.com',
  localStorageKey: 'serverless',
  auth0: {
    domain: 'auth.serverless.com',
    clientId: 'YAHOG8papb1tkrLttNVoVOSs4YLOjrNj',
    audience: 'https://serverlessinc.auth0.com/userinfo',
    scope: 'openid email_verified email profile name offline_access',
  },
  platform: {
    stage: 'prod',
    apiUrl: 'https://api.serverless.com',
    integrationsBase: 'https://integrations.core.serverless.com/api',
    coreApiUrl: 'https://core.serverless.com/api',
    sp: 'https://sp-providers-v1.serverless-platform.com',
    queryUrl: 'https://core.serverless.com/api/v2/query',
    integrationsUrl: 'https://integrations.core.serverless.com/api/integrations',
    alerts: 'https://alerts-api.core.serverless.com/v1',
  },
  stripe: {
    apiKey: 'pk_live_fUrnuMKodQM6UMXPZnFcNQ3N002icn5R5D',
  },
  mui: {
    licenseKey:
      'e7163c4816a16be174ebb724c5c5590aTz04OTQ3MixFPTE3NDYxMTI4MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  },

  // Hotjar tracking site id and Hotjar Snippet Version
  hotjar: {
    hjid: '3476560',
    hjsv: '6',
  },

  awsMarketplacePublicSubscriptionLink:
    'https://aws.amazon.com/marketplace/pp/prodview-ok24yw6x5wcrg',
  awsMarketplacePrivateSubscriptionLink:
    'https://aws.amazon.com/marketplace/saas/ordering?productId=prod-frvpmlhwbjj5o',
  calendly: 'https://slss.io/meet ',
  contact: {
    support: 'support@serverless.com',
  },
  hubspot: 2901603,
}

module.exports = env === 'prod' ? prodConfig : devConfig
